
.avatar-img {
    width: 150px;
    height: 150px;
    vertical-align: middle;
    border-radius: 50%;
}

.btn-secondary-upload:hover {
    color: #fff !important;
    background-color: #72859a !important;
    border-color: #6a7e95 !important;
}

.btn-secondary-upload {
    color: #fff !important;
    background-color: #8898aa !important;
    border-color: #8898aa !important;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

.mark-avatar {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    left: calc(50% - 75px);
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
}

.load-login {
    position: absolute;
    left: 12px;
    /*width: 80%;*/
    height: 80%;
    top: 10%;
}

.page-wrapper {
    height: 100vh;
}

.page-wrapper > .page-content {
    height: calc(100% - 32px);
    min-height: auto;
    padding: 30px 30px 0 30px;
}

.row-use {
    cursor: pointer;
}

.row-use:hover {
    background-color: #f6f9fc;
}

.card-info {
    height: 100%;
    position: relative;
}

.card-info .form-control {
    background-color: #e9ecef;
}

.little-profile .pro-img img {
    width: 160px;
    height: 160px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
}

.little-profile .pro-img {
    position: relative;
    margin-top: -105px;
    margin-bottom: 20px;
    height: 160px;
}

.info-group .form-control {
    height: auto;
}

.content-info {
    height: calc(100% - 200px);
}

.btn-info-student {
    position: absolute;
    top: 0;
    right: 0;
}

.avatar {
    object-fit: cover;
}


.icon-month {
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    color: white;
    display: inline-block;
    text-align: center;
}

.content-info-st {
    height: calc(100% - 190px);
}

.btn-custom-s {
    margin: 0 10px;
    width: 120px;
}

#main-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
}

.cell-c {
    background-color: #fafafa;
}

.select-row {
    background-color: rgba(38, 116, 196, 0.51) !important;

}

.select-row * {
    color: white !important;
}

button:focus {
    outline: none !important;
}

.menu-info {
    position: absolute;
    right: 6px;
    top: 7px;
}

.little-profile {
    position: relative;
}

.ra-dialog-add {
    min-width: 500px;
}
.ra-dialog-status{
    min-width: 300px;
}
.form-control-custom {
    min-height: 35px;
}
.text-inline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.load-page {
    overflow: hidden;
    background: white;
    z-index: 10;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.load-page-all {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    z-index: 100;
}
.nav-item{
    cursor: pointer;
}
.card-header-custom {
    background-color: #f2f2f2;
    display: flex;
    height: 160px;
    flex-direction: column;
    position: relative;
}

.button-cricle-custom {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
}

.switch-custom > span:last-child {
    color: rgb(103, 117, 124) !important;
}

.btn-edit-point {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-red {
    color: red !important;
}

.cell-center {
    line-height: 30px;

}

.ReactTable .rt-tbody .rt-tr-group {
    max-height: 44px;
}

.rt-tbody {
    overflow-y: overlay !important;
}

.select-class-point {
    min-width: 200px !important;
}

.user-profile .profile-img img {
    height: 50px;
    object-fit: cover;
}
.mont-input-ranking{
    width: 400px;
    float: right;
}
.process-all{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
